<template>
  <div>
    <b-card :title="$t('user.search')">
      <b-form @submit.prevent="searchData">
        <b-row v-if="userData.userGroupId === 4">
          <b-col md="6" lg="4">
            <b-form-group :label="$t('master.company.name')">
              <v-select v-model="search.companyId" label="companyName"
                        :options="optionCompanies" :reduce="option => option.companyId"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" lg="4">
            <b-form-group :label="$t('user.firstName')">
              <b-form-input v-model="search.userFirstName"/>
            </b-form-group>
          </b-col>

          <b-col md="6" lg="4">
            <b-form-group :label="$t('user.lastName')">
              <b-form-input v-model="search.userLastName"/>
            </b-form-group>
          </b-col>

          <b-col md="6" lg="4">
            <b-form-group :label="$t('user.position')">
              <v-select v-model="search.positionId" label="positionName"
                        :options="optionPositions" :reduce="option => option.positionId"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-center">
            <b-button type="submit" variant="info" class="my-1 mr-1">
              <feather-icon icon="SearchIcon"/>
              {{ $t('general.btnSearch') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>

    </b-card>

    <b-row>
      <b-col class="text-right">
        <b-button
            type="button"
            variant="success"
            class="mb-2 mr-2"
            @click="openRegisterPage"
            v-if="[4, 5].includes(userData.userGroupId)"
        >
          <feather-icon icon="ExternalLinkIcon"/>
          {{ $t('user.btnRegisterUrl') }}
        </b-button>
        <b-button
            type="button"
            variant="primary"
            :to="{name: 'pageUserAdd'}"
            class="mb-2"
            v-if="[4, 5].includes(userData.userGroupId)"
        >
          <feather-icon icon="PlusCircleIcon"/>
          {{ $t('general.btnAdd') }}
        </b-button>
      </b-col>
    </b-row>

    <b-overlay :show="isShowLoading">
      <b-card :title="$t('user.list')">
        <!-- table -->
        <vue-good-table
            mode="remote"
            :columns="tableData.columns"
            :rows="tableData.rows"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            :totalRows="tableData.totalRecords"
            :isLoading.sync="tableData.isLoading"
            :pagination-options="{ enabled: true }"
        >
          <template
              slot="table-row"
              slot-scope="props"
          >
            <div v-if="props.column.field === 'userFirstName'">
              {{ props.row.userFirstName }} {{ props.row.userLastName }}
            </div>

            <div v-else-if="props.column.field === 'userStatusName'">
              <b-badge :variant="statusColor(props.row.userStatusName)">{{ props.row.userStatusName }}</b-badge>
            </div>

            <div v-else-if="props.column.field === 'action'" class="text-nowrap text-center">
              <b-button
                  type="button"
                  variant="primary"
                  size="sm"
                  class="mr-1"
                  @click="approvalData(props.row.userId)"
                  v-b-tooltip.hover.top="$t('user.btnApproval')"
                  v-if="props.row.userStatus === 'W' && (userData.userGroupId === 4 || (userData.companyId === props.row.companyId && userData.userGroupId === 5))"
              >
                <feather-icon icon="UserCheckIcon"/>
              </b-button>
              <b-button
                  type="button"
                  variant="success"
                  size="sm"
                  class="mr-1"
                  @click="handleOpen(props.row.userId)"
                  v-b-tooltip.hover.top="$t('user.btnSummitExam')"
                  v-if="userData.userGroupId === 4"
              >
                <feather-icon icon="FileTextIcon"/>
              </b-button>
              <b-button
                  type="button"
                  variant="info"
                  :to="{name: 'pageUserView', params: {id: props.row.userId}}"
                  size="sm"
                  class="mr-1"
                  v-b-tooltip.hover.top="$t('general.btnView')"
              >
                <feather-icon icon="EyeIcon"/>
              </b-button>
              <b-button
                  type="button"
                  variant="warning"
                  :to="{name: 'pageUserEdit', params: {id: props.row.userId}}"
                  size="sm"
                  class="mr-1"
                  v-if="[4, 5].includes(userData.userGroupId)"
                  v-b-tooltip.hover.top="$t('general.btnEdit')"
              >
                <feather-icon icon="Edit2Icon"/>
              </b-button>
              <b-button
                  type="button"
                  variant="danger"
                  size="sm"
                  v-b-tooltip.hover.top="$t('general.btnDelete')"
                  @click="deleteData(props.row.userId)"
                  v-if="userData.userId !== props.row.userId && [4,5].includes(userData.userGroupId)"
              >
                <feather-icon icon="Trash2Icon"/>
              </b-button>
            </div>

            <div v-else>{{ props.formattedRow[props.column.field] }}</div>
          </template>

          <!-- pagination -->
          <template
              slot="pagination-bottom"
              slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('general.tableShowing') }} 1 {{ $t('general.tableTo') }}
            </span>
                <b-form-select
                    v-model="tableData.pageLength"
                    :options="tableData.pageLengthOption"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> {{ $t('general.tableOf') }} {{ props.total }} {{ $t('general.tableEntries') }} </span>
              </div>
              <div>
                <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="tableData.pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-overlay>

    <b-modal
        ref="modal-score-record"
        cancel-variant="outline-secondary"
        :ok-title="$t('general.deleteConfirm')"
        hide-footer
        :cancel-title="$t('general.deleteCancel')"
        centered
        :title="$t('user.btnSummitExam')"
        @hide="handleClose"
    >

      <b-form @submit.prevent="saveData">
        <validation-observer ref="validateForm" #default="{invalid}">
          <b-row>

            <b-col cols="12">
              <b-form-group :label="$t('training.score') + '*'">
                <validation-provider #default="{ errors }" :name="$t('training.score')" rules="required|integer|min:0|between:0,20">
                  <b-form-input v-model="exam.score"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group :label="$t('training.scoreFile')">
                <b-form-file v-model="exam.file" accept=".jpg, .gif, .pdf, .png"/>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>

      <div class="mt-3 text-right">
        <b-button variant="outline-secondary" class="mb-1 mr-1" @click="handleCancel">
          {{ $t('general.deleteCancel') }}
        </b-button>
        <b-button variant="success" class="mb-1" @click="saveData" :disabled="isBtnDisabled">
          <b-spinner small v-if="isBtnDisabled"/>
          <feather-icon icon="SaveIcon" v-else/>
          {{ $t('general.btnSubmit') }}
        </b-button>
      </div>
    </b-modal>

  </div>

</template>

<script>
import {BAvatar, BButton, BCard, BCol, BForm, BFormGroup, BBadge, BFormInput, BFormSelect, BOverlay, BPagination, BRow, VBTooltip, BModal, BFormFile, BSpinner} from 'bootstrap-vue'
import {required, min, between, integer} from '@validations'
import {VueGoodTable} from 'vue-good-table'
import tableMixins from "@/mixins/tableMixins"
import masterMixins from "@/mixins/masterMixins"
import {UserService, FileStorageService} from "@/services"

const userService = new UserService()
const fileStorageService = new FileStorageService()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BAvatar,
    BPagination,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BOverlay,
    VueGoodTable,
    BModal,
    BFormFile,
    BSpinner,
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  mixins: [tableMixins, masterMixins],
  async created() {
    let [listCompany, listPositions] = await Promise.all([
      this.listCompany(),
      this.listPosition()
    ])
    this.optionCompanies = listCompany
    this.optionPositions = listPositions

    let columns = [
      {
        label: this.$t('master.company.name'),
        field: 'companyName',
        width: '15%',
        sortable: true
      }, {
        label: this.$t('user.firstName'),
        field: 'userFirstName',
        width: '20%',
        sortable: true
      }, {
        label: this.$t('user.position'),
        field: 'positionName',
        width: '10%',
        sortable: true
      },  {
        label: this.$t('user.userGroup'),
        field: 'userGroupName',
        width: '10%',
        sortable: false
      }, {
        label: this.$t('user.licenseDateEnd'),
        field: 'userLicenseEndDate',
        width: '10%',
        sortable: false
      }, {
        label: this.$t('user.trainingLatestScore'),
        field: 'userLatestTrainingScore',
        width: '10%',
        sortable: false
      }, {
        label: this.$t('user.status'),
        field: 'userStatusName',
        width: '10%',
        sortable: true
      }, {
        label: '',
        field: 'action',
        width: '10%',
        sortable: false
      },
    ]
    // v-if="[4, 5].includes(userData.userGroupId)"
    if (this.userData.userGroupId !== 4) {
      columns = columns.filter(c => c.field !== 'companyName')
    }

    this.tableData.columns = columns
    this.listData()
  },
  data: () => ({
    isShowLoading: true,
    isBtnDisabled: false,
    pk: '',
    search: {
      companyId: '',
      userFirstName: '',
      userLastName: '',
      positionId: '',
    },
    userData: JSON.parse(localStorage.getItem('userData')),
    optionCompanies: [],
    optionPositions: [],
    exam: {
      pk: '',
      file: [],
      score: '',
    }
  }),
  computed: {},
  watch: {
    tableChange() {
      this.listData()
    }
  },
  methods: {
    listData() {
      this.getTableQueryString()
      userService.listData(this.tableQueryString).then(queryResult => {
        let {isSuccess, data} = queryResult
        if (isSuccess) {
          this.tableData.rows = data.results
          this.tableData.totalRecords = data.totalRow
        } else {
          this.$store.commit('main/setToastError', data)
        }
        this.isShowLoading = false
      })
    },
    searchData() {
      this.onColumnFilter(this.search)
    },
    deleteData(id) {
      this.$bvModal
          .msgBoxConfirm(this.$t('general.deleteDescription'), {
            title: this.$t('general.deleteTitle'),
            okVariant: 'primary',
            okTitle: this.$t('general.deleteConfirm'),
            cancelTitle: this.$t('general.deleteCancel'),
            cancelVariant: 'outline-secondary',
          })
          .then(async value => {
            if (value) {
              let queryResult = await userService.deleteData(id)
              let {isSuccess, data} = queryResult
              if (isSuccess) {
                this.isShowLoading = true
                this.listData()
                this.$store.commit('main/setToastSuccess', {title: 'Delete success', text: ''})
              } else {
                this.$store.commit('main/setToastError', data)
              }
            }
          })
    },
    approvalData(id) {
      this.$bvModal
          .msgBoxConfirm(this.$t('user.btnApproval'), {
            title: this.$t('general.alertApprove'),
            okVariant: 'primary',
            okTitle: this.$t('general.btnApprove'),
            cancelTitle: this.$t('general.btnDisapprove'),
            cancelVariant: 'outline-secondary',
          })
          .then(async value => {
            if(typeof value === 'boolean') {
              let approvalStatus
              if(value) {
                approvalStatus = 'Y'
              } else {
                approvalStatus = 'N'
              }

              let queryResult = await userService.approvalData(id, {userApproval: approvalStatus})
              let {isSuccess, data} = queryResult
              if (isSuccess) {
                this.isShowLoading = true
                this.listData()
                this.$store.commit('main/setToastSuccess', {title: 'Delete success', text: ''})
              } else {
                this.$store.commit('main/setToastError', data)
              }
            }
          })
    },
    statusColor(statusName) {
      if (statusName === 'Active') {
        return 'primary'
      } else if (statusName === 'Inactive') {
        return 'secondary'
      } else if (statusName === 'Blacklist') {
        return 'danger'
      } else {
        return 'dark'
      }
    },
    handleOpen(id) {
      this.exam.pk = id
      this.$refs['modal-score-record'].show()
    },
    handleCancel() {
      this.$refs['modal-score-record'].hide()
    },
    handleClose() {
      this.exam.pk = ''
      this.exam.file = []
      this.exam.score = ''
    },
    async saveData() {
      this.$refs.validateForm.validate().then(async success => {
        if (success) {
          this.isBtnDisabled = true

          let uploadResult = {fileId: null}
          if (Object.entries(this.exam.file).length > 0) {
            uploadResult = await fileStorageService.upload(this.exam.file)
            // {isSuccess: true, message: 'Success', fileId: 8}
            if (!uploadResult.isSuccess) {
              this.$store.commit('main/setToastError', {message: uploadResult.message})
              this.isBtnDisabled = false
              return false
            }
          }

          let actionData = {
            fileId: uploadResult.fileId,
            score: this.exam.score,
          }

          userService
              .scoreRecord(this.exam.pk, actionData)
              .then(async ({isSuccess, data}) => {
                if (isSuccess) {
                  await this.listData()
                  this.$refs['modal-score-record'].hide()
                  this.$store.commit('main/setToastSuccess', {title: 'Save success', text: ''})
                  this.isBtnDisabled = false
                } else {
                  this.$store.commit('main/setToastError', data)
                  this.isBtnDisabled = false
                }
              })
        }
      })
    },
    openRegisterPage() {
      let routeData = this.$router.resolve({name: 'publicRegister', params: {companyId: this.userData.companyId}})
      window.open(routeData.href, '_blank')
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

